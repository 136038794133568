(function ($) {
    let $fn = $(".comp_carousel_services");

    if ($fn.length) {
        let $carousel = $fn.find("[data-carousel]"),
            $nav_items = $fn.find("[data-nav-item]");

        if($carousel.children().length > 1) {
            cssLoaded(()=>{
                $carousel.flickity({
                    prevNextButtons: false,
                    pageDots: false,
                    autoPlay: false,
                    draggable: false,
                    accessibility: false,
                    setGallerySize: false,
                });
                $nav_items.on("mouseenter click",function () {
                    let $el = $(this);
                    $nav_items.filter(".mod--active").removeClass("mod--active");
                    $el.addClass("mod--active");
                    $carousel.flickity( 'select', $el.data("nav-item"));
                })
            })
        }
    }
})(jQuery);