(function ($) {
    let $fn = $(".comp_carousel_team");

    if ($fn.length) {
        let $carousel = $fn.find("[data-carousel]");

        if($carousel.children().length > 3) {
            cssLoaded(()=>{
                $carousel.flickity({
                    cellAlign: "left",
                    wrapAround: true,
                    pageDots: false,
                })
            })
        }
    }
})(jQuery);