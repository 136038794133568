(function ($) {
    let $fn = $(".comp_site_article");

    if ($fn.length) {
        let $carousel = $fn.find("[data-carousel]"),
            $gallery = $fn.find("[data-gallery]");

        if($carousel.children().length > 4) {
            cssLoaded(()=>{
                $carousel.flickity({
                    cellAlign: "left",
                    wrapAround: true,
                    pageDots: false,
                })
            })
        }

        if($gallery.length) {
            $.getScript(cdnjs.lightgallery).done(()=>{
                $gallery.lightGallery({
                    thumbnail: true,
                    selector: ".elm_gallery_item",
                    exThumbImage: 'data-exthumbimage',
                    fullScreen: false,
                    zoom: true,
                    actualSize: false,
                    hash: false,
                    download: true,
                    autoplay: false,
                    autoplayControls: false
                });
            })
        }
    }
})(jQuery);