(function ($) {
    let $fn = $(".comp_site_articles");

    if ($fn.length) {
        let $nav_items = $fn.find(".elm_nav .elm_nav_item"),
            $nav_select = $fn.find("[data-nav-select]");

        $nav_items.each(function (n) {
            let $el = $(this);

            $el.on("click",function () {
                if(!$el.hasClass("mod--active")){
                    $nav_items.filter(".mod--active").removeClass("mod--active");
                    $el.addClass("mod--active");
                    //AJAX
                    sr.sync();
                }
            })
        });

        $nav_select.on("change",function () {
            $nav_items[$(this)[0].selectedIndex-1].click();         
        })
    }
})(jQuery);