(function($) {
    fn_nl_lib_anchor();

    if($('form[data-recaptcha]').length) {
        grecaptcha.ready(function() {
            grecaptcha.execute('6Leyt2sUAAAAAKPJDGR9mTkmm_ugmNXwr1we9ksf', {action: 'form'})
                .then(function(token) {
                    $('#g-token').val(token);
                });
        });
    }

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    // nase sluzby
    doc.on('click', '[data-servises]', function (e) {
        e.preventDefault();
        let $el = $(this);
        let componentId = $el.closest('.comp_site_services').attr('id');

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (data) {
                var snippetDataHead = $(data['snippets'][componentId]).find('#'+componentId+'_head');
                $('#'+componentId+'_head').replaceWith(snippetDataHead);

                var snippetDataContent = $(data['snippets'][componentId]).find('#'+componentId+'_content');
                $('#'+componentId+'_content').replaceWith(snippetDataContent);

                window.history.replaceState(null, data.replaceState.title || null, data.replaceState.url);
                document.title = data.replaceState.title;

                sr.sync();
            }
        });
    });

    // o firme
    doc.on('click', '[data-firma]', function (e) {
        e.preventDefault();
        let $el = $(this);
        let componentId = $el.closest('.comp_site_tabs').attr('id');

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (data) {
                var snippetDataContent = $(data['snippets'][componentId]).find('#'+componentId+'_content');
                $('#'+componentId+'_content').replaceWith(snippetDataContent);

                window.history.replaceState(null, data['payload']['siteTitle'] || null, data['payload']['siteUrl']);
                document.title = data.replaceState.title;

                sr.sync();
            }
        });
    });

    //tiskove zpravy
    doc.on('click', '[data-category]', function (e) {
        e.preventDefault();
        let $el = $(this);
        let componentId = $el.closest('.comp_site_articles').attr('id');

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (data) {
                var snippetDataContent = $(data['snippets'][componentId]).find('#'+componentId+'_articles');
                $('#'+componentId+'_articles').replaceWith(snippetDataContent);

                sr.sync();
            }
        });
    });

    doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        let componentId = frm.closest('.comp_site_contact').attr('id');

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                var snippetDataHead = $(payload['snippets'][componentId]).find('#'+componentId+'_form');
                $('#'+componentId+'_form').replaceWith(snippetDataHead);
                sr.sync();
            }
        });
    });

    doc.on('click','[data-component-ajax]',function(e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if(!url || !url.length) {
            url = $(this).attr('href');
        }

        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");

        if($(".mod--sticky").length) {
            $.getScript(cdnjs.stickyfill).done(()=>{
                let $stickies = doc.find(".mod--sticky");
                Stickyfill.add($stickies);
            })
        }
    }
})(jQuery);
