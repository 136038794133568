(function ($) {
    let $fn = $(".comp_site_services");

    if ($fn.length) {
        let $nav_items = $fn.find(".elm_services_nav .elm_nav_item"),
            $nav_select = $fn.find("[data-nav-select]");

        $nav_items.each(function (n) {
            let $el = $(this);

            $el.on("click",function () {
                if(!$el.hasClass("mod--active")){
                    $nav_items.filter(".mod--active").removeClass("mod--active");
                    $el.addClass("mod--active");
                    $nav_select[0].selectedIndex = n+1;
                    sr.sync();
                }
            })
        });

        $nav_select.on("change",function () {
            $nav_items[$(this)[0].selectedIndex-1].click();
        })

        let $carousel = $fn.find("[data-carousel]"),
            $nav_carousel = $fn.find("[data-nav-carousel-item]");

        if($carousel.children().length > 1) {
            cssLoaded(()=>{
                $carousel.flickity({
                    prevNextButtons: false,
                    pageDots: false,
                    autoPlay: false,
                    draggable: false,
                    accessibility: false,
                    setGallerySize: false,
                });
                $nav_carousel.on("mouseenter click",function () {
                    let $el = $(this);
                    $nav_carousel.filter(".mod--active").removeClass("mod--active");
                    $el.addClass("mod--active");
                    $carousel.flickity( 'select', $el.data("nav-carousel-item"));
                })
            })
        }
    }
})(jQuery);